<template>
    <base-page>
        <titlu-pagina Titlu="Categorii drepturi" @on_add_clicked="show_dialog()" :AdaugaVisible="$has_right('adaugare-categorie-drepturi')" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">

                        <el-col :span='4'>
                            <el-form-item label='Name' >
                                <el-input v-model='Filters.Name' />
                            </el-form-item>
                        </el-col>                        
                        <el-col :span='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='Name' label='Name'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip v-if="$has_right('modificare-categorie-drepturi')" content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip v-if="$has_right('stergere-categorie-drepturi')" content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Categorii-drepturi-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from "@/pages/BasePage";
    import Categorii_drepturi_dialog from '@/pages/categorii-drepturi/Categorii-drepturi_dialog.vue';
    import TitluPagina from '@/widgets/TitluPagina';

    export default {
        name: "categorii-drepturi",
        extends: BasePage,
        components: {
            'base-page': BasePage,
            'Categorii-drepturi-dialog': Categorii_drepturi_dialog,
            'titlu-pagina': TitluPagina
        },
        data () {
            return {
                Results: [],
                base_url: '',
                Info: {
                },
                Filters: {Name: '' ,             },
                    
                OrderBy: { },
                PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            }
        },
        methods: {

            async get_info(){
                if (!this.$has_right('vizualizare-categorii-drepturi')){
                this.$router.push('/no-rights')
                return
                }
                var response        = await this.post("categorii_drepturi/get_info" );
                this.refresh_info();
            },

            async refresh_info(){
                var response        = await this.post("categorii_drepturi/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
                this.Results        = response.Results;
                this.PaginationInfo = response.PaginationInfo;
                //
                this.select_menu_item('categorii-drepturi');
            },
            reset(){
                this.Filters = {Name: '' };                    
                this.refresh_info();
            },
            async delete_item( item ){            
                this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                    type: 'warning'
                    }).then(async() => {
                        await this.post("categorii_drepturi/delete_item", { id: item.Id } );
                        this.refresh_info(); 
                        this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                        }).catch(() => {
                            this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                            });
            },
            show_dialog(id){
                this.$refs.dlg.show_me(id);
            }
        },
        mounted(){
            this.base_url = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
